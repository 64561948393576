import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from 'react-router-dom';
import HoverRating, { LittleToAlot } from '../components/Rating';
import { saveSurvey } from '../db';
import { config } from "dotenv";
config();

// https://stackoverflow.com/questions/61215349/material-ui-stepper-not-keeping-state-when-move-next-or-back

let svcURL = process.env.REACT_APP_DOVAXIS_API_PRIMARY;


const steps = [  
  {    
    title: "So...what do you think?",
    panelName: 'Panel1'
  },
  { 
    title: 'Reaction',
    panelName: 'Panel2'
  },
  { 
    title: 'Feedback',
    panelName: 'Panel3'
  },
  { 
    title: 'Are you ready?',
    panelName: 'Panel4'
  },
  { 
    title: "That's it!",
    panelName: 'Panel5'
  }
];

const Panel1 = (props) => {
  return <div style={{paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
  We're investing heavily into helping Long-term Care Facilities.
    <p>
      Please share your thoughts to help us continue to make great products.  
    </p>
    <p style={{paddingTop: '10px'}}>
        It only takes a minute to complete.
    </p>
  </div>;
};

const Panel2 = (props) => {

  const { orgRating, setOrgRating, personalRating, setPersonalRating } = props;  

  return (<div>
    {/* Rating 1*/}
    <div style={{paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
      How helpful do you think this could be at your organization?
    </div>
    <HoverRating value={orgRating} setValue={setOrgRating}/>

    {/* Rating 2*/}
    <div style={{marginTop: '24px', paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
     How helpful would BrightSource be for you personally in your role?
    </div>
    <HoverRating value={personalRating} setValue={setPersonalRating} labels={LittleToAlot} percision={1} />

  </div>);
};


const Panel3 = (props) => {
  const {mostValueable, setMostValuable, suggestions, setSuggestions} = props;  

  return ( <div>
      {/* Question */}
      <div style={{paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
        What feature or aspect of BrightSource do you find most valuable so far?
      </div>
      <textarea value={mostValueable} onChange={(event) => { setMostValuable(event.target.value)}} style={{borderColor: 'lightgray', borderWidth:1, borderStyle: 'solid',  width: '100%', minHeight: '60px', padding: '8px'}} />

      {/* Question */}
      <div style={{marginTop: '24px', paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
        Is there anything you feel is missing or could be improved?
      </div>
      <textarea value={suggestions} onChange={(event) => { setSuggestions(event.target.value)}} style={{borderColor: 'lightgray', borderWidth:1, borderStyle: 'solid',  width: '100%', minHeight: '60px', padding: '8px'}} />

      
    </div>
  )
};

const Panel4 = (props) => {

  const { waysToHelp, setWaysToHelp } = props;  

  return (
    <div>
      {/* Question */}
      <div style={{paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
        Can we help you prepare your organization for AI?  
        <div>
          <div>
          If so, let us know how.  We're here to help!
          </div>
          Or please share anything else.  
        </div>
      </div>
      <textarea value={waysToHelp} onChange={(event) => { setWaysToHelp(event.target.value)}} style={{borderColor: 'lightgray', borderWidth:1, borderStyle: 'solid',  width: '100%', minHeight: '120px', padding: '8px'}} />
    </div>
  ) 
}

const Panel5 = (props) => {
  return (
    <div >
      {/* Question */}
      <div style={{paddingBottom: '8px', fontSize: '18px', fontWeight: 500}}>
      <div>
        Thank you for your valuable feedback!
      </div>
      Your input helps us improve BrightSource and tailor it to better meet your needs and those of our healthcare community.
      </div>
      <div style={{width: '100%'}}>
      <img style={{margin: 'auto', padding: '12px'}} 
        alt="we are here to help" 
        src="https://firebasestorage.googleapis.com/v0/b/lumberjack-396203.appspot.com/o/app%2FBrightSource%2Frobot-thank-you.png?alt=media&token=3b84e955-8030-4491-80bd-78c63f6892c1"/>
      </div>
    </div>
  ) 
}

// https://www.google.com/search?q=react+pass+props+to+dynamic+component&rlz=1C1RXQR_enUS1124US1125&oq=react+pass+props+to+dy&gs_lcrp=EgZjaHJvbWUqBwgAEAAYgAQyBwgAEAAYgAQyBggBEEUYOTIICAIQABgWGB4yCAgDEAAYFhgeMg0IBBAAGIYDGIAEGIoFMg0IBRAAGIYDGIAEGIoFMg0IBhAAGIYDGIAEGIoFMgoIBxAAGIAEGKIEMgoICBAAGIAEGKIEMgoICRAAGKIEGIkF0gEINDQ5OGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8
const Panel = (props) => {
  const components = {
    'Panel1': Panel1,
    'Panel2': Panel2,
    'Panel3': Panel3,
    'Panel4': Panel4,
    'Panel5': Panel5
  };

  const ComponentToRender = components[props.name];

  return (
    <>
      {ComponentToRender ? <ComponentToRender {...props} /> : null}
    </>
  );
}

export default function WowSurvey(props) {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [nextButtonText, setNextButtonText] = useState("Next");  
  const maxSteps = steps.length;
  const navigate = useNavigate();
  const currentUser = props?.user;


  // Panel State
  const [ orgRating, setOrgRating ] = useState();                               // Panel 2
  const [ personalRating, setPersonalRating ] = useState();                               // Panel 2
  const [ mostValueable, setMostValuable ] = useState();                     // Panel 3
  const [ suggestions, setSuggestions ] = useState();                       // Panel 3
  const [ waysToHelp, setWaysToHelp ] = useState();                         // Panel 4

  // Panel State Props
  let panelStateProps = {orgRating, setOrgRating, personalRating, setPersonalRating, mostValueable, setMostValuable, suggestions, setSuggestions, waysToHelp, setWaysToHelp };

  /*
  const submitSurvey = () => {

    // user email
    // hubspot id
    
  }
    */

  const handleClose = () => {
    setOpen(false);
    // TODO: Write to API? 
    /*
     submitSurvey(
      currentUser,
      orgRating,
      personalRating,
      mostValueable,
      suggestions,
      waysToHelp
    )
      */

    if(props.onClose){
      props.onClose();
    }
    // navigate("/");
  };

 

  const handleNext = () => {

    if(nextButtonText == 'Submit'){
      publishWowSurvey(currentUser, orgRating, personalRating, mostValueable, suggestions, waysToHelp); // publish results
      handleClose();
    }

    if(activeStep + 2 == steps.length){
      setNextButtonText('Submit');
    }

    if(activeStep + 1 != maxSteps){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }    
  };

  const handleBack = () => {
   
    if(activeStep - 1 < steps.length){
      setNextButtonText('Next');
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  

  /*

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOutsideClick = e => {
    if (!this.node.contains(e.target)) this.handleClick();
  };

  const handleClick = () => {
    if (!this.state.showModal) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  */

  return (
    <ClickAwayListener onClickAway={() => false}> 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="wow-survey-title"
        aria-describedby="wow-survey-description"
      >
        <DialogTitle id="wow-survey-title" style={{color: '#297d8b', fontSize: '24px'}}>
            {steps[activeStep].title}
        </DialogTitle>
        <DialogContent>
          <Panel name={steps[activeStep].panelName} {...panelStateProps}  />           
        </DialogContent>
        <DialogActions>
          {activeStep == 0 &&
            <>
            <Button onClick={handleClose}  variant='outline' >
                Skip
            </Button>
            <Button color='success' variant='contained' onClick={handleNext} autoFocus style={{marginRight: '10px'}}>Continue</Button>
            </>
          }
          {activeStep != 0 &&
            <div style={{width:'100%', backgroundColor: 'white'}}>
                <MobileStepper
                    variant="progress"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    style={{backgroundColor: 'white'}}
                    nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                    >
                        { nextButtonText }
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                        ) : (
                        <KeyboardArrowRight />
                        )}
                    </Button>
                    }
                    backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                        ) : (
                        <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                    }
                />
            </div>
          }
        </DialogActions>
      </Dialog>
    </ClickAwayListener>
  );
}

async function publishWowSurvey(user, orgRating, personalRating, mostValueable, missingfeature, nextSteps){

  let surveyName = 'wow';

  try {
    console.log(`Publishing "${surveyName}" survey results`);

    let email = user?.Email || user?.email;

    // prepare args
    let data = ' '; 
    if(hasValue(orgRating)){
      data += `wow_survey_rating_organization: ${orgRating}, `;
    }
    if(hasValue(personalRating)){
      data += `wow_survey_rating_personal: ${personalRating}, `;
    }
    if(hasValue(mostValueable)){
      data += `wow_survey_most_valuable: "${personalRating}", `;      
    }
    if(hasValue(missingfeature)){
      data += `wow_survey_missing_feature: "${missingfeature}", `;
    }
    if(hasValue(nextSteps)){
      data += `wow_survey_next_steps: "${nextSteps}", `;
    }

    // remove last comma
    if(data.trim().endsWith(',')){
      data = data.trim().slice(0, -1);
    }

    debugger;

    // record in firebase
    let query = `
      mutation submitSurvey {
        submitSurvey ( email: "${email}", surveyName: "${surveyName}", data: {${data}}) {
        code
        message
        successful
        }
    }`;

    // save copy in db
    saveSurvey(email, surveyName, data, query);

    // submit to API
    const response = await fetch(`${svcURL}/HubSpot/GraphQL`, {
      method: "POST",      
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: query,
      }),
    });

    let result = await response.json();
    if(result?.errors != null){
      console.log(result.errors[0]);
      throw Error(result.errors[0].message);
    }
    return result?.data?.submitSurvey;
  }
  catch(ex){
    console.log(`An error occured while processing UserFlowEventFired: ${ex.message}`);
    //throw new Error(`An error occured while processing UserFlowEventFired: ${ex.message}`);
  }

}

function hasValue(data){
  if(data == null || data == undefined) return false;
  if(typeof data == 'string'){
    if(data.toString().trim() == '')
      return false;
  }
  return true;
}