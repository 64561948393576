import React, {lazy} from 'react';

const Layout = lazy(() => import('./Layout'));
const HomePage = lazy(() => import('./dashboards/HomePage/index.js'));
const UsageReport = lazy(() => import('./dashboards/UsageReport/UsageReport.js'));

const ReportRoutes = {
  routes: [
    {
      path: 'reports',
      element: <Layout />,
      children: [     
        { path: '', element: <HomePage /> },
        { 
          path: 'usage',
          element: <UsageReport />
        }
      ]
    }
  ]
}

export default ReportRoutes;