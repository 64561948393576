import { useState, useEffect } from 'react';
import { AuthContext }  from './AuthContext';
import firebaseAuth from './auth/services/firebase/firebaseAuthService';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice'; // these updates redux state
import history from '@history';

/*
  AutoProvider 
  ------------------
  Wires Authorization functionality for the app, including the following:
  
    - isAuthenticated:                indicates if logged in
    - currentUser:                    authenticated user
    - createUser:                     creates new user
    - signOut:                        logs out user
    - signInWithEmailAndPassword:     log's in user by email and password
    - sendPasswordReset               send password reset link
    - setPassword                     resetPassword
  
  Docs / Resources:
    - https://github.com/firebase/quickstart-js/blob/master/auth/email-password.ts
*/

function AuthProvider({currentUser, setCurrentUser, children}) {
  
  //const [currentUser, setCurrentUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const useFirebaseAuth = true;

  useEffect(() => {
    
    firebaseAuth.on('onAutoLogin', (user) => {
            
      if(user) {
        success(user, 'Signed in');
      }
      else {        
        pass('');
      }

    }); // onAutoLogin

    firebaseAuth.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    firebaseAuth.on('onLogout', () => {      
      pass('Signed out');            
      dispatch(logoutUser());
    });

    firebaseAuth.on('onAutoLogout', (message) => {
      pass(message);      
      dispatch(logoutUser());
    });

    firebaseAuth.on('onNoAccessToken', () => {
      pass();
    });

    firebaseAuth.init();

  }, []); // useEffect

  function signInWithEmailAndPassword(email, password) {

    return new Promise(async (resolve, reject) => {    
      if(useFirebaseAuth){

        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
                    
            //setTimeout(() => history.push('/'), 50); 
            resolve(user);
            /*
            if(user?.data?.Homepage != null){            
              setTimeout(() => history.push(user.data.Homepage), 0); 
            }
            else {
              setTimeout(() => history.push('/'), 0); 
            }
            */
          })
          .catch((errors) => {
            reject(errors);
          }
      );
      }
      else {
  
        /*
        jwtService
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
           
          })
          .catch((errors) => {
            reject(errors);
        });
        */
      }
    }) // promise
  }

  function signOut() {
    firebaseAuth.logout();
    setCurrentUser(null);
    setTimeout(() => history.push('/sign-in'), 1000); 
  }

  function passwordReset() {
    
    //firebaseAuth.logout();
    //setCurrentUser(null);
    setTimeout(() => history.push('/reset-password'), 50); 
  }

  function success(user, message) {      

    if (message) {
      dispatch(showMessage({ message, 
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }}
      ));
    }
    
    Promise.all([
      
      dispatch(setUser(user)), 
      setCurrentUser(user),
      // You can receive data in here before app initialization
      // setTimeout(() => history.push('/'), 1000)
      
    ]).then((values) => {
      
      setWaitAuthCheck(false);
      setIsAuthenticated(true);
    });

  }; // success

  function pass(message) {
    if (message) {
      // dispatch(showMessage({ message }));
    }

    setWaitAuthCheck(false);
    setIsAuthenticated(false);
  } // pass
  
  function sendPasswordReset(email){
    return new Promise(async (resolve, reject) => {    

      firebaseAuth
        .sendPasswordReset(email)
          .then(() => {
            resolve();
          })
          .catch(errors => {
            reject(errors);
          })      
      })
  }

  function resetPassword(code, password){
    return firebaseAuth
      .resetPassword(code, password)
  }

  async function createUserInOrg(email, password, data, org, notificationConfig={sendVerification: false, sendInvite: true}){
    
    // ensure user is mapped to org
    if(org != null){ 
      data.orgPrimaryKey = org.key;
      if(data?.orgRef == null){ // define org refs
        data.orgRefs = [{
          id: org.id,
          key: org.key
        }]
      }
      else { // add if missing        
        let orgFound = false;
        if(data.orgRef.forEach(entry => {
          if(entry.id == org.id){
            orgFound = true;          
          }
        }))
        if(!orgFound){
          data.orgRef.push({
            id: org.id,
            key: org.key
          })
        }
      }      
    } 

    return firebaseAuth.createUserInOrg(email, password, data)
      .then((user) => {
        return user;
      })
      .catch((errors) => {
        throw errors;
      });

  } // createUserInOrg

  function createUser(email, password, data, sendVerification=true){
    return new Promise(async (resolve, reject) => {    
      firebaseAuth
      .createUser(email, password, data)
        .then((user) => {
                    
          if(sendVerification){
            firebaseAuth.sendVerificationEmailToUser();
          }          

          // TODO: 
          // Sync Lead Data

          dispatch(setUser(user)),
          setCurrentUser(user);
          resolve(user);

          if(user?.data?.Homepage){
            //setTimeout(() => history.push(user?.data?.Homepage), 500); 
          }else {
            //setTimeout(() => history.push('/'), 500); 
          }
          
        })
        .catch((errors) => {
          reject(errors);
        });
      }
    );      
  } // createUser

  function confirmEmail(code){
    return firebaseAuth.confirmEmail(code);
  }

  function recaptchaVerifier() {
    return firebaseAuth.recaptchaVerifier();
  }

  function signInWithPhoneNumber(phone, recaptchaSignature) {
    return firebaseAuth.signInWithPhoneNumber(phone, recaptchaSignature);
  }

  const handlers = ({
    // user handlers
    currentUser, createUser, createUserInOrg, isAuthenticated,
    // authentication handlers
    signOut, signInWithEmailAndPassword, confirmEmail,
    // reset handlers
    passwordReset, sendPasswordReset, resetPassword,
    // recaptcha and phone handlers
    recaptchaVerifier, signInWithPhoneNumber
    
  })

  if(waitAuthCheck){
    return (<FuseSplashScreen />)
  }

  return (<AuthContext.Provider value={handlers}>{children}</AuthContext.Provider>)
}

export default AuthProvider;