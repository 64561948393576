import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useSearchParams } from 'react-router-dom';
import createFastpassUser from './createFastpassUser';
// TODO: For machines with multiple users, the cid-email should match
import useAuthContext from 'src/app/AuthContext';
import history from '@history';

function FastPassPage() {
  const [ searchParams ] = useSearchParams();
  const [ sent, setSent ] = useState(false);
  const [ title, setTitle ] = useState('Finishing setup...');
  const [ description, setDescription ] = useState(`We're setting up your free BrightSource.`);
  const { signInWithEmailAndPassword } = useAuthContext();

  let email = searchParams.get("email")
  let name = searchParams.get("name");

  useEffect( () => {

    if(!sent && email != null){    
      let msg = (name)? `Hi ${name},`: 'Finishing setup...';
      setTitle(msg);

      setSent(true);
      console.log(`Creating FastPass User (${email})`);
      createFastpassUser(email)
        .then(result => {
          if(result.successful) {
            console.log('User Registered');
            setTitle('Setup complete');
            setDescription("Logging you in...");
            // signin
            setTimeout(() => 
              signInWithEmailAndPassword(email, "ExperienceAI")
                .then( user => {
                  console.log('Signin Succeeded.');
                  // everything should be handled already 
                  console.log(`User ${user?.displayName} has authenticated.`);
                  setTimeout(() => history.push('/Demo'), 50);
                })
                .catch(error => {
                  if(error.message.indexOf('wrong-password') > -1){
                    setTitle('Login failed');
                    setDescription("Your account already exists with a unique password.");
                    setTimeout(() => history.push('/sign-in'), 250);
                  }
                  else {
                    setTitle('Login failed');
                    setDescription("We are missing essential information to authenticate you.");
                    setTimeout(() => history.push('https://www.dovaxis.com/offer'), 250);
                  }                  
                }
              )
            , 100);
          }
          else {            
            console.log(`We're missing some details.`);
            setTitle(`We're missing some details.`);
            setDescription("Please sign up. Rerouting you now...");
            setTimeout(() => history.push('https://www.dovaxis.com/offer'), 350);
          }
          
        })
        .catch(errorMessage => {
          console.log('Registration Error');
          setTitle(`We're missing some details.`);
          setDescription("Please sign up. Rerouting you now...");
          setTimeout(() => history.push('https://www.dovaxis.com/offer'), 350);
        })      
    } 
  }, [email])


  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img src="assets/logo/logo.svg" alt="logo" style={{marginLeft: '-40px'}}/>

          <Typography className="mt-0 text-3xl font-bold tracking-tight leading-tight">
            {title}
          </Typography>
          <div className="flex items-baseline mt-4 font-medium">
            <Typography className="text-xl">{ description }</Typography>            
          </div>
          
          <div>
          </div>
        </div>
      </Paper>

      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: 'primary.main' }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-10"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-6xl font-bold leading-none text-gray-100">            
            <div>Human Powered. </div>
            <div style={{marginTop: '2px'}}>AI Enhanced.</div>
          </div>
          <div className="mt-24 text-3xl tracking-tight leading-6 text-gray-400">
            {/* Dovaxis solutions redefine conventional healthcare. */}
            DOVAXIS is Healthcare AI.
          </div>
          <div className="mt-8 text-xl tracking-tight leading-6 text-gray-400">            
            Fast and agile solutions that elevate care.
          </div>
          <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                '& .MuiAvatar-root': {
                  borderColor: 'primary.main',
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              50 organizations are elevating care.  It's your turn. 
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default FastPassPage;