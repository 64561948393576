import { config } from "dotenv";
config();

//let svcURL = process.env.REACT_APP_DOVAXIS_API_SECONDARY;
let svcURL = process.env.REACT_APP_DOVAXIS_API_PRIMARY;

async function createFastpassUser(email){

    // credentials: "include",

    try {
      const response = await fetch(`${svcURL}/BrightSource`, {
        method: "POST",      
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
            mutation createFastPassUser {
              fastpass(email: "${email}") {
              code
              message
              successful
              }
            }`,
        }),
      });
  
      let result = await response.json();
      let data = result?.data;
      let fastpass = data?.fasspass;
      console.log(result?.data?.fastpass);
      return result?.data?.fastpass;
    }
    catch(ex){

      throw new Error(`An error occured creating you're fast pass. ${ex.message}`);
    }
    
}

export default createFastpassUser;
